import { domains } from '@/constants'
import { IStructuredDataProps } from '@/types'

export const useStructuredData = ({ breadcrumbs }: IStructuredDataProps) => {
  // const { t: $t } = useI18n()
  const config = useRuntimeConfig()

  // Structured data markup
  // see: https://developers.google.com/search/docs/appearance/structured-data/search-gallery

  // TODO: update it and use Q&A with FAQ pages

  const ldJson = [
    {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      alternateName: ['بايانو', 'باي يانو'],
      url: domains.payiano,
      name: 'Payiano'
    },
    {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      url: config.public.siteUrl,
      logo: `${config.public.siteUrl}/images/logos/logo-colored-squared.png`,
      name: 'Payiano',
      legalName: 'Payiano for Digital Payment.',
      description:
        'Payiano is a secure and reliable payment service provider to help you process online payments with the ease and harmony of a well-orchestrated performance.',
      sameAs: [
        'https://x.com/payiano',
        'https://youtube.com/@payiano',
        'https://linkedin.com/company/payiano',
        'https://facebook.com/payiano',
        'https://instagram.com/payianocom',
        domains.github,
        domains.postman,
        'https://reddit.com/r/payiano',
        'https://tiktok.com/@payiano'
      ]
    },
    {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbs.map((breadcrumb, index) => ({
        '@type': 'ListItem',
        name: breadcrumb.name,
        item: breadcrumb.url,
        position: breadcrumb.position ?? index + 1
      }))
    }
  ]

  useHead({
    script: [
      {
        type: 'application/ld+json',
        innerHTML: JSON.stringify(ldJson)
      }
    ]
  })
}
